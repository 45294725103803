import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Adjust, Star } from "@mui/icons-material";
import { MenuItem } from "@mui/material";
import prettyBytes from "pretty-bytes";

import { repoTypes } from "types";

import { RepositoryIcon } from "components/atoms";
import { useFavorites } from "store";

const RepoOption = (repo: repoTypes.Repository & { groupId?: string }) => {
  const navigate = useNavigate();
  const { repoId } = useParams();

  const handleClick = () => {
    navigate(`/groups/${repo.groupId}/repos/${repo.id}/envs`);
  };

  const totalEnvs = useMemo(() => {
    return (
      repo.envCount?.building + repo.envCount?.failed + repo.envCount?.built
    );
  }, [repo.envCount]);

  const active = useMemo(() => {
    return repoId === repo.id ? "!bg-brand/10" : "";
  }, [repoId, repo.id]);

  const { isFavorite } = useFavorites({
    type: "repository",
    visitedId: repo.id,
    data: {},
  });

  const sliceLength = useMemo(() => {
    return (
      12 + (!totalEnvs ? 4 : 0) + (!repo.size ? 8 : 0) + (!isFavorite ? 0 : 4)
    );
  }, [totalEnvs, repo.size, isFavorite]);

  return (
    <MenuItem
      key={repo.id}
      value={repo.id}
      className={"flex items-end gap-s !font-normal " + active}
      onClick={handleClick}
      title={repo.name}
    >
      <RepositoryIcon type={repo.type} size="small" />
      {repo.name.slice(0, sliceLength) +
        (repo.name.length > sliceLength ? "..." : "")}
      {(repo.size ?? 0) > 0 && (
        <span className="text-s text-neutral ml-auto">
          {prettyBytes(repo.size ?? 0)}
        </span>
      )}
      {totalEnvs > 0 && (
        <span
          className={
            "text-s text-neutral flex items-center " +
            (repo.size ? "" : "ml-auto")
          }
        >
          {totalEnvs}{" "}
          <Adjust className="text-s text-neutral" fontSize="inherit" />
        </span>
      )}
      {isFavorite && (
        <Star
          className={
            "text-s text-neutral flex items-center " +
            (repo.size || totalEnvs ? "" : "ml-auto")
          }
          color="primary"
          fontSize="inherit"
        />
      )}
    </MenuItem>
  );
};

export default RepoOption;
