import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { To, useNavigate } from "react-router-dom";
import { AxiosError } from "axios";

import {
  Alert,
  Button,
  Divider,
  FormHelperText,
  MenuItem,
  TextField,
} from "@mui/material";

import { repoTypes } from "types";
import { groupsService, repositoriesService } from "services";
import { FormTitle } from "components/atoms";

const NewPrivateRegistryForm = (props: {
  parentId: string;
  redirectUrl: To | number;
}) => {
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm<repoTypes.PrivateRegistry>();

  const {
    mutate: createRepoPrivateRegistry,
    isLoading: loadingRepo,
    isError: isRepoError,
    error: repoError,
  } = repositoriesService.useCreatePrivateRegistry(props.parentId);

  const handleFormSubmit: SubmitHandler<repoTypes.PrivateRegistry> = (data) => {
    createRepoPrivateRegistry(data, {
      onSuccess: () => {
        typeof props.redirectUrl === "number"
          ? navigate(props.redirectUrl)
          : navigate(props.redirectUrl);
      },
    });
  };

  return (
    <form
      className="flex flex-col gap-s p-m min-w-[350px]"
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <FormTitle>New private registry</FormTitle>

      <Alert severity="info" className="max-w-[300px]">
        You can add private registries and then configure your qaack file to use your private images. For gitlab, use a personal token with read_registry scope and your username as the username.
      </Alert>

      <Controller
        name="address"
        control={control}
        render={({ field }) => (
          <TextField
            label="Address"
            {...field}
            onChange={(e) => field.onChange(e.target.value.replace(" ", "-"))}
          />
        )}
      />

      <Divider className="my-m" />

      <Controller
        name="username"
        control={control}
        render={({ field }) => (
          <TextField
            label="Username"
            {...field}
            onChange={(e) => field.onChange(e.target.value.replace(" ", "-"))}
          />
        )}
      />

      <Controller
        name="password"
        control={control}
        render={({ field }) => (
          <TextField
            label="Token"
            {...field}
            inputProps={{ type: "password" }}
            onChange={(e) => field.onChange(e.target.value.replace(" ", "-"))}
          />
        )}
      />

      <Button className="font-bold" variant="contained" type="submit">
        Create
      </Button>

      {(loadingRepo || loadingRepo) && <p>Loading...</p>}
      {isRepoError && (
        <FormHelperText error>
          {(repoError as AxiosError<any>)?.response?.data?.error}
        </FormHelperText>
      )}
    </form>
  );
};

export default NewPrivateRegistryForm;
