import { MenuItem } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import { envTypes } from "types";
import { EnvStatusIcon, GitRefIcon } from "components/atoms";
import { useMemo } from "react";
import { useFavorites } from "store";
import { Star } from "@mui/icons-material";

const EnvOption = (
  env: envTypes.Environment & { groupId?: string; repoId?: string}
) => {
  const navigate = useNavigate();
  const { envId } = useParams();

  const handleClick = () => {
    navigate(`/groups/${env.groupId}/repos/${env.repoId}/envs/${env.id}`);
  };

  const name = useMemo(() => env?.name ?? env?.gitRef?.name ?? "", [env]);
  const activeClass = useMemo(() => {
    return envId === env.id ? "!bg-brand/10" : "";
  }, [envId, env.id]);

  const {isFavorite} = useFavorites({ type: "environment", visitedId: env.id, data: {} });

  return (
    <MenuItem
      key={env.id}
      value={env.id}
      onClick={handleClick}
      className={"flex justify-between gap-s !font-normal " + activeClass}
      title={name}
    >
      <GitRefIcon {...env} size="small" />
      {name.slice(0, 16) + (name.length > 16 ? "..." : "")}
      <span className="text-s text-neutral flex items-center ml-auto">
        <EnvStatusIcon {...env} />
      </span>
      {isFavorite && <Star fontSize="inherit" className="!fill-brand" />}
    </MenuItem>
  );
};

export default EnvOption;
