import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Group, Inventory, Star } from "@mui/icons-material";
import { MenuItem } from "@mui/material";
import prettyBytes from "pretty-bytes";

import { groupTypes } from "types";
import { useFavorites } from "store";

const GroupOption = (group: groupTypes.Group) => {
  const navigate = useNavigate();
  const { groupId } = useParams();

  const handleClick = () => {
    navigate(`/groups/${group.id}/repos`);
  };

  const { isFavorite } = useFavorites({
    type: "group",
    visitedId: group.id,
    data: {},
  });

  const sliceLength = useMemo(() => {
    return (
      12 +
      (!group.repoCount ? 4 : 0) +
      (!group.size ? 8 : 0) +
      (!isFavorite ? 4 : 0)
    );
  }, [group.repoCount, group.size, isFavorite]);

  const active = useMemo(() => {
    return groupId === group.id ? "!bg-brand/10" : "";
  }, [groupId, group.id]);

  return (
    <MenuItem
      key={group.id}
      value={group.id}
      onClick={handleClick}
      className={"flex items-end gap-s !font-normal " + active}
      title={group.name}
    >
      <Group className="!fill-brand" fontSize="small" />
      {group.name.slice(0, sliceLength) +
        (group.name.length > sliceLength ? "..." : "")}
      {group.size && (
        <span className="text-s text-neutral flex items-center ml-auto">
          {prettyBytes(group.size ?? 0)}
        </span>
      )}
      {group.repoCount > 0 && (
        <span
          className={
            "text-s text-neutral flex items-center " +
            (group.size ? "" : "ml-auto")
          }
        >
          {group.repoCount}{" "}
          <Inventory className="text-s text-neutral" fontSize="inherit" />
        </span>
      )}
      {isFavorite && (
        <Star
          color="primary"
          className={
            "text-s text-neutral flex items-center " +
            (group.size || group.repoCount ? "" : "ml-auto")
          }
          fontSize="inherit"
        />
      )}
    </MenuItem>
  );
};

export default GroupOption;
