import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Adjust, LinkOutlined, Settings } from "@mui/icons-material";
import { Link } from "@mui/material";

import { repositoriesService, wsService } from "services";
import { useFavorites, useRecentlyVisited } from "store";

import {
  ButtonTertiary,
  FavoriteButton,
  RepositoryIcon,
  Title,
} from "components/atoms";
import { EnvironmentLists, RepoSettings } from "components/organisms";

import NormalPage from "./layout/page";

export default function Repository(props: {
  newVar?: boolean;
  newPrivateRegistry?: boolean;
}) {
  let params = useParams();
  const navigate = useNavigate();

  const [showSettings, showEnvs] = useMemo(
    () => [params.tab === "settings", params.tab === "envs"],
    [params.tab]
  );

  const handleTabSwitch = (tab: string) => {
    const currentPath = window.location.pathname;
    const newPath = params.tab
      ? currentPath.replace(params.tab, tab)
      : currentPath + "/" + tab;
    navigate(newPath);
  };

  const {
    data: repoData,
    refetch: refreshRepo,
    isLoading: repoLoading,
  } = repositoriesService.useRepo(params.repoId);

  wsService.useRepoSubscription(params.repoId ?? "missing", refreshRepo);

  const entryListItem = useMemo(
    () =>
      ({
        type: "repository",
        data: { ...repoData, groupId: params.groupId },
        visitedId: repoData?.id,
      }) as const,
    [repoData, params.groupId]
  );
  useRecentlyVisited(entryListItem);
  const { toggleFavorite, isFavorite } = useFavorites(entryListItem);

  return (
    <NormalPage
      title={
        <div className="flex items-center gap-s">
          <RepositoryIcon type={repoData?.type} size="xl" />
          <div>
            <Title>{repoData?.name}</Title>
            {repoData?.details && (
              <Link
                href={repoData.details.url}
                className="hover:!font-brand/50 overflow-hidden text-m !no-underline flex items-center"
              >
                <LinkOutlined /> &nbsp;
                {repoData.details.url}
              </Link>
            )}
          </div>
        </div>
      }
      tabs={[
        <ButtonTertiary
          onClick={() => handleTabSwitch("settings")}
          active={showSettings}
        >
          <Settings /> &nbsp; Settings
        </ButtonTertiary>,
        <ButtonTertiary
          onClick={() => handleTabSwitch("envs")}
          active={showEnvs}
        >
          <Adjust /> &nbsp; Envs
        </ButtonTertiary>,
        <FavoriteButton
          isFavorite={isFavorite}
          toggleFavorite={toggleFavorite}
        />,
      ]}
    >
      {showEnvs && (
        <EnvironmentLists
          environments={repoData?.environments || []}
          reusableEnvironments={repoData?.reusableEnvironments || []}
          loading={repoLoading}
          refresh={refreshRepo}
          repoId={params.repoId || "missing"}
        />
      )}
      {showSettings && (
        <RepoSettings
          modalOpen={
            props.newVar
              ? "newVar"
              : props.newPrivateRegistry
                ? "newPrivateRegistry"
                : ""
          }
          repoId={params.repoId || ""}
          repoName={repoData?.name || ""}
          repoUrl={repoData?.url || ""}
          repoType={repoData?.type || ""}
          refreshRepo={refreshRepo}
        />
      )}
    </NormalPage>
  );
}
