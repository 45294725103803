import { useMemo } from "react";
import { FaExclamation, FaPlay, FaStop } from "react-icons/fa6";
import { envTypes } from "types";
import Loader from "../Loader";

const EnvRunningStatusIcon = (env: envTypes.Environment ) => {
  const icon = useMemo(() => {
    switch (env.runningStatus || "") {
      case "running":
        return <FaPlay />;
      case "stopped":
        return <FaStop />;
      case "starting":
        return <Loader />;
      case "stopping":
        return <Loader />;
      case "error":
        return <FaExclamation />;
      default:
        return null;
    }
  }, [env.runningStatus]);

  const colorClass = useMemo(() => {
    switch (env.runningStatus || "") {
      case "running":
        return "text-status-success";
      case "error":
        return "text-status-error";
      case "starting":
      case "stopping":
        return "text-status-warning";
      case "stopped":
      default:
        return "text-neutral/60";
    }
  }, [env.runningStatus]);

  return (
    <div className={"text-s text-neutral font-bold flex items-center gap-xs " + colorClass}>
      {icon}
    </div>
  );
};

export default EnvRunningStatusIcon;
