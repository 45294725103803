import { AutoLogin } from "utils";

import Environment from "./Environment";
import Groups from "./Groups";
import Group from "./Group";
import Repository from "./Repository";
import NotFound from "./404";
import ServiceLogs from "./ServiceLogs";
import ServiceConsole from "./ServiceConsole";
import EnvironmentBuildLogs from "./EnvironmentBuildLogs";
import LogIn from "./LogIn";
import Register from "./Register";
import Dashboard from "./Dashboard";
import Profile from "./Profile";
import Activate from "./Activate";
import Welcome from "./Welcome";

export const routes = [
  { path: "register", element: <Register /> },
  { path: "activate/:id", element: <Activate /> },
  { path: "login", element: <LogIn /> },
  { path: "login/auto", element: <AutoLogin /> },
  { path: "/", element: <Welcome /> },
  {
    path: "dashboard",
    element: <Dashboard />,
  },
  { path: "profile", element: <Profile /> },
  { path: "profile/tokens/new", element: <Profile newToken /> },
  { path: "groups", element: <Groups /> },
  { path: "groups/:groupId/:tab", element: <Group key="repos" /> },
  {
    path: "groups/:groupId/:tab/newVar",
    element: <Group key="newVar" newVar />,
  },
  {
    path: "groups/:groupId/:tab/newMember",
    element: <Group newMember key="newMember" />,
  },
  {
    path: "groups/:groupId/repos/:repoId/:tab",
    element: <Repository key="repo" />,
  },
  {
    path: "groups/:groupId/repos/:repoId/:tab/newVar",
    element: <Repository newVar key="newVar" />,
  },
  {
    path: "groups/:groupId/repos/:repoId/:tab/newPrivateRegistry",
    element: <Repository newPrivateRegistry key="newPrivateRegistry" />,
  },
  {
    path: "groups/:groupId/repos/:repoId/envs/:envId",
    element: <Environment />,
  },
  { path: "environment/:envId/build/logs", element: <EnvironmentBuildLogs /> },
  { path: "service/:servId/logs", element: <ServiceLogs /> },
  { path: "service/:servId/console", element: <ServiceConsole /> },
  {
    path: "*",
    element: <NotFound />,
  },
];
