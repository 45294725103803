import { useCallback, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Divider,
  IconButton,
  SwipeableDrawer,
  Tooltip,
  debounce,
} from "@mui/material";
import { Add, ContentPaste } from "@mui/icons-material";

import { repositoriesService } from "services";
import { repoTypes } from "types";

import {
  SaveOnBlurInput,
  SelectorInput,
  Subtitle,
  SwitchButton,
} from "components/atoms";
import {
  CodeEditor,
  PrivateRegistryTeaser,
  SettingOption,
  VariableTeaser,
} from "components/molecules";
import {
  ExtList,
  NewPrivateRegistryForm,
  NewVarForm,
} from "components/organisms";

import { NotificationsState } from "store";

const AVAILABLE_WEBHOOK_INTEGRATIONS = [
  "gitlab",
  "github",
  "azure",
  "bitbucket",
];

const RepoSettings = (props: {
  repoId: string;
  repoName: string;
  repoUrl: string;
  repoType: string;
  modalOpen: string | null;
  refreshRepo: () => void;
}) => {
  const { data: settings, refetch: refresh } =
    repositoriesService.useRepoSettings(props.repoId);

  const availableScheduledRebuildTypes = [
    "disabled",
    "reusables",
    "all",
  ] as repoTypes.ScheduledRebuildMode[];

  const availableQaackFileModes = [
    "panel",
    "repo",
    "both",
  ] as repoTypes.QaackFileMode[];

  const {
    mutate: updateRepo,
    isLoading: isUpdateRepoLoading,
    isError: isUpdateRepoError,
  } = repositoriesService.useUpdateRepo(props.repoId);

  const {
    mutate: updateSettings,
    isLoading: isUpdateSettingsLoading,
    isError: isUpdateSettingsError,
  } = repositoriesService.useUpdateSettings(props.repoId);

  const { mutate: deleteRepo } = repositoriesService.useDeleteRepo(
    props.repoId
  );

  const { addNotification } = useContext(NotificationsState);

  const sshkeyRef = useRef<HTMLDivElement>(null);

  const handleCopyClick = () => {
    if (sshkeyRef.current) {
      navigator.clipboard.writeText(sshkeyRef.current.innerText);
      addNotification({
        message: "SSH key copied to clipboard!",
        type: "success",
        id: "copied-ssh-key",
      });
    }
  };

  const handleNameBlur = (newValue: string | number) => {
    if (newValue !== props.repoName)
      updateRepo(
        { name: newValue.toString() },
        {
          onSuccess: () => {
            addNotification({
              message: "Repository name updated!",
              type: "success",
              id: "updated-repo-name",
            });
            props.refreshRepo();
          },
        }
      );
  };

  const handleTTLBlur = (newValue: string | number) => {
    if (newValue !== settings?.environmentTTL?.toString())
      updateSettings(
        { newTTL: Number(newValue) },
        {
          onSuccess: () => {
            addNotification({
              message: "Environment TTL updated!",
              type: "success",
              id: "updated-ttl",
            });
            refresh();
          },
        }
      );
  };

  const handleScheduleChange = (
    newMode?: repoTypes.ScheduledRebuildMode,
    scheduledTime?: string
  ) => {
    updateSettings(
      {
        newScheduledRebuild: {
          mode: newMode ?? "disabled",
          hour: scheduledTime,
        },
      },
      {
        onSuccess: () => {
          addNotification({
            message: "Scheduled rebuild config updated!",
            type: "success",
            id: "updated-scheduled-time",
          });
          refresh();
        },
      }
    );
  };

  const persistQaackFile = useCallback(
    (newMode?: repoTypes.QaackFileMode, newQaackFile?: string) => {
      updateSettings(
        {
          newQaackFile: {
            qaackFile: newQaackFile ?? settings?.qaackFile?.qaackFile,
            mode: newMode ?? "repo",
          },
        } as any,
        {
          onSuccess: () => {
            addNotification({
              message: "Qaack file settings updated!",
              type: "success",
              id: "updated-qaack-file-settings",
            });
            refresh();
          },
        }
      );
    },
    [updateSettings, addNotification, refresh, settings?.qaackFile?.qaackFile]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const deboucedPersist = useCallback(debounce(persistQaackFile, 1000), [
    persistQaackFile,
  ]);

  const navigate = useNavigate();

  const handleDelete = () => {
    deleteRepo(null as any, {
      onSuccess: () => {
        navigate(window.location.pathname.split("/repos")[0] + "/repos");
      },
    });
  };

  const NewVarModal = useCallback(
    () => (
      <SwipeableDrawer
        anchor="right"
        open={props.modalOpen === "newVar"}
        onOpen={() => navigate("newVar")}
        onClose={() => navigate(-1)}
      >
        <NewVarForm parent="repo" parentId={props.repoId} redirectUrl={-1} />
      </SwipeableDrawer>
    ),
    [props.modalOpen, props.repoId, navigate]
  );

  const NewRegistryModal = useCallback(
    () => (
      <SwipeableDrawer
        anchor="right"
        open={props.modalOpen === "newPrivateRegistry"}
        onOpen={() => navigate("newPrivateRegistry")}
        onClose={() => navigate(-1)}
      >
        <NewPrivateRegistryForm parentId={props.repoId} redirectUrl={-1} />
      </SwipeableDrawer>
    ),
    [props.modalOpen, props.repoId, navigate]
  );

  // webhook settings
  const handleWebhookSettingsChange = useCallback(
    (newSettings: repoTypes.WebhookTriggerSettings) => {
      updateSettings(
        {
          webhook: {
            ...settings?.webhook,
            ...newSettings,
          },
        },
        {
          onSuccess: () => {
            addNotification({
              message: "Webhook triggers settings updated!",
              type: "success",
              id: "updated-webhook-settings",
            });
            refresh();
          },
        }
      );
    },
    [settings?.webhook, updateSettings, addNotification, refresh]
  );

  return (
    <>
      <div className="flex flex-col gap-5 pb-10">
        <Subtitle>General</Subtitle>
        <SettingOption
          type="normal"
          title="Repo Name"
          description="This is the name used on Qaack, it won't affect the repository itself."
          actions={
            <SaveOnBlurInput
              value={props.repoName}
              onBlur={handleNameBlur}
              loading={isUpdateRepoLoading}
              error={isUpdateRepoError}
              name="name"
              type="text"
            />
          }
        />

        <Subtitle>Environments</Subtitle>
        <SettingOption
          type="normal"
          title="TTL"
          description="This is the time in minutes after which an environment based on this repo will be automatically deleted."
          actions={
            <SaveOnBlurInput
              value={settings?.environmentTTL}
              onBlur={handleTTLBlur}
              loading={isUpdateSettingsLoading}
              error={isUpdateSettingsError}
              name="ttl"
              type="number"
              inputProps={{ min: 0 }}
              unit="minutes"
            />
          }
        />
        <SettingOption
          type="normal"
          title="Scheduled rebuild"
          description="You can schedule a rebuild of all or some environments based on this repo for a specific time of the day. Environments will be rebuilt based on the selected mode and time."
          actions={
            <div className="flex flex-col gap-s">
              <SelectorInput
                label="Mode"
                value={settings?.scheduledRebuild?.mode}
                onChange={(v) => {
                  handleScheduleChange(v as repoTypes.ScheduledRebuildMode);
                }}
                options={availableScheduledRebuildTypes}
              />
              {settings?.scheduledRebuild?.mode !== "disabled" && (
                <SaveOnBlurInput
                  type="time"
                  name="hour"
                  loading={isUpdateSettingsLoading}
                  error={isUpdateSettingsError}
                  value={settings?.scheduledRebuild?.hour}
                  onBlur={(newScheduledTime) =>
                    handleScheduleChange(
                      settings?.scheduledRebuild?.mode,
                      newScheduledTime.toString()
                    )
                  }
                  inputProps={{ min: 0 }}
                />
              )}
            </div>
          }
        />

        {AVAILABLE_WEBHOOK_INTEGRATIONS.includes(props.repoType) && (
          <>
            <Subtitle>Integrations</Subtitle>
            <SettingOption
              type="normal"
              title="Rebuild trigger webhooks"
              // You can configure which git references will trigger a rebuild of the environments based on this repo.
              description="You can activate or deactivate the webhook triggers for branch, tag and PR/MR changes (new commits added). You can also set patterns to include or exclude branches, the included branches will trigger the rebuild and the excluded ones won't. An example of a pattern is 'main', 'main,develop' or 'feature/*,fix/*'."
              actions={
                <div className="flex flex-col gap-s">
                  <SwitchButton
                    checked={settings?.webhook?.triggerBranchEnable ?? false}
                    onChange={(checked) =>
                      handleWebhookSettingsChange({
                        triggerBranchEnable: checked,
                      })
                    }
                    label="Branch"
                  />
                  <div className="flex flex-col gap-s mb-s">
                    <SaveOnBlurInput
                      type="text"
                      name="branch"
                      placeholder="feature/*"
                      unit="included"
                      disabled={!settings?.webhook?.triggerBranchEnable ?? true}
                      onBlur={(newValue) =>
                        handleWebhookSettingsChange({
                          triggerBranchInclude: newValue.toString(),
                        })
                      }
                      loading={isUpdateSettingsLoading}
                      error={isUpdateSettingsError}
                      value={settings?.webhook?.triggerBranchInclude ?? ""}
                    />
                    <SaveOnBlurInput
                      type="text"
                      name="branch"
                      placeholder="main"
                      unit="excluded"
                      disabled={!settings?.webhook?.triggerBranchEnable ?? true}
                      onBlur={(newValue) =>
                        handleWebhookSettingsChange({
                          triggerBranchExclude: newValue.toString(),
                        })
                      }
                      loading={isUpdateSettingsLoading}
                      error={isUpdateSettingsError}
                      value={settings?.webhook?.triggerBranchExclude ?? ""}
                    />
                  </div>

                  <Divider />

                  <SwitchButton
                    checked={settings?.webhook?.triggerTagEnable}
                    onChange={(checked) =>
                      handleWebhookSettingsChange({
                        triggerTagEnable: checked,
                      })
                    }
                    label="Tag"
                  />

                  <Divider />

                  <SwitchButton
                    checked={settings?.webhook?.triggerRequestEnable}
                    onChange={(checked) =>
                      handleWebhookSettingsChange({
                        triggerRequestEnable: checked,
                      })
                    }
                    label="PR/MR"
                  />
                </div>
              }
            />
          </>
        )}

        <Subtitle>Auth</Subtitle>

        <SettingOption
          type="normal"
          title="SSH Key"
          description="This is the public key that will be used to connect to the repo."
          fullWidthActions
          actions={
            <div className="w-full">
              <div
                ref={sshkeyRef}
                className="rounded-2 break-words text-xs font-mono text-justify leading-tight font-extralight"
              >
                {settings?.sshPublicKey || "There is no public key set!"}
              </div>
              <Tooltip
                title="Copy SSH key to clipboard"
                className="group-hover:opacity-100"
              >
                <ContentPaste
                  onClick={handleCopyClick}
                  className="absolute right-s top-s group-hover:opacity-100 opacity-0 transition-opacity cursor-pointer text-brand"
                />
              </Tooltip>
            </div>
          }
        />

        <ExtList
          title="Private Registries"
          items={settings?.privateRegistries ?? []}
          listActions={
            <Tooltip title="New registry">
              <IconButton onClick={() => navigate("newPrivateRegistry")}>
                <Add />
              </IconButton>
            </Tooltip>
          }
          createEntry={(registry: repoTypes.PrivateRegistry) => (
            <PrivateRegistryTeaser
              {...registry}
              parentId={props.repoId}
              onDelete={refresh}
            />
          )}
          addItemAction={
            <Button
              variant="text"
              onClick={() => navigate("newPrivateRegistry")}
              className="w-full flex items-center"
            >
              <Add />
              Add a new registry
            </Button>
          }
          emptyMsg={"No private registries set!"}
        />

        <Divider />
        <br />
        
        <Subtitle>Configuration file</Subtitle>

        <SettingOption
          type="normal"
          title="Qaack file mode"
          description="You can decide from where Qaack will take the `qaack.yml`, you can add it to a new `.qaack` file folder on your repository for each different branch or provide it here in the panel. If you want to use both ways Qaack will use the one in the repository by default and the panel one when the branch doesn't have one."
          actions={
            <SelectorInput
              label="Mode"
              value={settings?.qaackFile?.mode}
              onChange={(v) => {
                persistQaackFile(v as repoTypes.QaackFileMode);
              }}
              options={availableQaackFileModes}
            />
          }
        />

        {settings?.qaackFile?.mode !== "repo" && (
          <SettingOption
            type="normal"
            title="Qaack file"
            description={
              <>
                Qaack will use this file to create and build environments for
                this repository, if you are using 'both' file mode, this will
                only be used if the git ref of the environment doesn't have a
                qaack file. Please refer to{" "}
                <a
                  href="http://docs.qaack.cloud/general/Environment.html#qaackfile-environment-config-file"
                  target="_blank"
                  className="underline"
                  rel="noreferrer"
                >
                  documentation
                </a>{" "}
                for a Qaackfile description and/or the{" "}
                <a
                  className="underline"
                  href="https://docs.qaack.cloud/specifications/Qaackfile.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Qaackfile reference
                </a>{" "}
                for more information.
              </>
            }
            fullWidthActions
            actions={
              <CodeEditor
                className="!w-full p-xs"
                value={settings?.qaackFile?.qaackFile ?? ""}
                onChange={(value) => {
                  deboucedPersist(settings?.qaackFile?.mode, value);
                }}
                label="Qaack file"
              />
            }
          />
        )}

        <ExtList
          title="Variables"
          items={settings?.variables ?? []}
          listActions={
            <Tooltip title="New variable">
              <IconButton onClick={() => navigate("newVar")}>
                <Add />
              </IconButton>
            </Tooltip>
          }
          createEntry={(variable: repoTypes.Variable) => (
            <VariableTeaser
              {...variable}
              parentType="repo"
              parentId={props.repoId}
              onDelete={refresh}
              key={variable.name}
            />
          )}
          addItemAction={
            <Button
              variant="text"
              onClick={() => navigate("newVar")}
              className="w-full flex items-center"
            >
              <Add />
              Add a new variable
            </Button>
          }
          emptyMsg={"No variables set!"}
        />

        <Divider />

        <div className="my-l">
          <h2 className="mb-s text-l text-status-error font-bold">
            Danger Zone
          </h2>
          <SettingOption
            type="danger"
            title="Delete repository"
            description="Deleting a repository will delete all environments and variables associated to it."
            actions={
              <Button
                className="w-full"
                variant="outlined"
                color="error"
                onClick={handleDelete}
              >
                Delete
              </Button>
            }
          />
        </div>
      </div>
      <NewVarModal />
      <NewRegistryModal />
    </>
  );
};

export default RepoSettings;
